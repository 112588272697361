<template>
  <div class="FinancialRecordsDetails">
    <el-page-header class="mb-40" @back="$router.go(-1)" content="财务记录明细"></el-page-header>
    <div class="f-w-b">
      <NavigationBar></NavigationBar>
      <div class="main">
        <div class="banner">
          {{ enterpriseStateInfo.enterpriseName }}
        </div>
        <div class="boxShadow infoTable">
          <div class="title">记录信息</div>
          <div>
            <div v-for="(item, index) of recordInfo" :key="index">
              <div v-if="item.value" class="f-c-b titleItem">
                <span class="titval">{{ item.title }}</span>

                <!-- 跳订单详情页 -->
                <div class="f1" v-if="item.title == '销售单号'">
                  <span style="color:blue">{{ item.value }}</span>
                  <el-button style="margin-left:50px" type="primary"
                    @click="HandleBtn('salesOrder', relationId)">查看</el-button>
                </div>
                <!-- 跳库存管理记录明细 -->
                <div class="f1" v-else-if="item.title == '退回单号'">
                  <span style="color:blue">{{ item.value }}</span>
                  <el-button style="margin-left:50px" type="primary" @click="HandleBtn('ReturnOrder', stockRecordId)">查看
                  </el-button>
                </div>
                <!-- 跳退货单详情页 -->
                <div class="f1" v-else-if="item.title == '退货单编号'">
                  <span style="color:blue">{{ item.value }}</span>
                  <el-button style="margin-left:50px" type="primary"
                    @click="HandleBtn('returnGoods', relationId)">查看</el-button>
                </div>
                <div class="f-c f1" v-else-if="item.title == '变更类型' && (stockCtype == 'CINP' || stockCtype == 'COUT')">
                  <span class="f1">{{ item.value }}</span>
                  <el-button type="primary" @click="checkSourceRecord">查看源记录</el-button>
                </div>


                <span v-else class="value">{{ item.value }}</span>
              </div>
            </div>
          </div>

          <div v-if="supplierInfo && supplierInfo.length != 0">
            <div class="title mt-30">供应商信息</div>
            <div>
              <div v-for="(item, index) of supplierInfo" :key="index">
                <div v-if="item.value" class="f-c-b titleItem">
                  <span class="titval">{{ item.title }}</span>
                  <span class="value">{{ item.value }}</span>
                </div>
              </div>
            </div>
          </div>


          <div v-if="customerinfo && customerinfo.length != 0">
            <div class="title mt-30">客户信息</div>
            <div>
              <div v-for="(item, index) of customerinfo" :key="index">
                <div v-if="item.value" class="f-c-b titleItem">
                  <span class="titval">{{ item.title }}</span>
                  <span class="value">{{ item.value }}</span>
                </div>
              </div>
            </div>
          </div>


          <div class="title mt-30" v-if="stockRecordList.length != 0">库存关联</div>
          <div class="w--100" v-if="stockRecordList.length != 0 && stockCtype == 'SAIN0A'">
            <div class="f-w-b h-60 talTit1">
              <span>产品名称</span>
              <span>销售名称</span>
              <span>库存变化</span>
            </div>
            <div class="talVal1 f-w-b" v-for="(item, index) of stockRecordList" :key="index">
              <span>{{ item.productName }}</span>
              <span>{{ item.customerUseName }}</span>
              <span>{{ item.InventoryChanges }}</span>
            </div>
          </div>
          <div class="w--100" v-else-if="stockRecordList.length != 0 && stockCtype != 'SAIN0A'">
            <div class="f-w-b h-60 talTit">
              <span>产品名称</span>
              <span>库存变化</span>
            </div>
            <div class="talVal f-w-b" v-for="(item, index) of stockRecordList" :key="index">
              <span>{{ item.productName }}</span>
              <span>{{ item.InventoryChanges }}</span>
            </div>
          </div>
          <div class="title mt-30">备注信息</div>
          <div class="modifyNote">
            {{ stockChangeNote }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TableColumn2 } from '@/utils/TableColumn'
import { UrlDecodeId } from '@/mixins/UrlDecodeMixin'
import { FinancialTypeClass } from '@/utils/FinancialTypeClass'
export default {
  name: 'FinancialRecordsDetails',
  components: {
    NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
  },
  mixins: [UrlDecodeId],
  data() {
    return {
      detailsInfo: [],
      recordInfo: [],
      supplierInfo: [],
      customerinfo: [],
      stockChangeNote: '',//备注信息
      relationId: '',//查看源关联id
      stockCtype: '',
      stockRecordId: '',//库存关联id
      stockRecordList: [],//库存关联列表
      stockRecordListTableColumn: TableColumn2,
      enterpriseStateInfo: {},//权限
    }
  },
  created() {
    this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
    this.productStockAmountListDetails(this.queryId)
  },
  methods: {
    // 产品库存单据财务数据列表详情接口
    productStockAmountListDetails(stockRecordId) {
      let that = this;
      that.$http.productStockAmountListDetails({
        changeUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        stockRecordId
      }).then(res => {
        let { stockCtype, createDate, relationId, valuationCurrency, amountChangeType, amountChangeNumber2,
          amountBeforeNumber2, amountAfterNumber2, changeUsername, sdName, sdPhone, sdContact, sdCity, sdAddress,
          stockChangeNote, relationStr, stockRecordId, productName, stockChangeType, stockChangeNumber } = res.data,
          address = sdCity + sdAddress,
          stockCtypeText = FinancialTypeClass.getStockCtype(stockCtype),
          ChangeNoType = FinancialTypeClass.getChangeNoType(stockCtype);//区分变更信息中编号类型  1-销售单编号（跳转） 2-退回单编号（不跳转） 3-退货单编号（跳转）
        if (res.data.stockCtype == 'SAOT0A' || res.data.stockCtype == 'SAIN0A') {
          let stockRecordList = res.data.stockRecordList ? res.data.stockRecordList : [];
          if (stockRecordList.length) {
            stockRecordList.forEach(item => {
              item.InventoryChanges = item.stockChangeType + (item.stockChangeNumber ? item.stockChangeNumber.toFixed(2) : item.stockChangeNumber)
            })
          }
          that.stockRecordList = stockRecordList;
        }
        else {
          if (productName && stockChangeType && stockChangeNumber) {
            let data = [{
              productName: productName,
              InventoryChanges: stockChangeType + (stockChangeNumber ? stockChangeNumber.toFixed(2) : stockChangeNumber)
            }];
            that.stockRecordList = data;
          }else {
            that.stockRecordList = [];
          }
        }
        that.stockChangeNote = stockChangeNote ? stockChangeNote : '无';//备注信息
        that.stockCtype = stockCtype;
        that.relationId = relationId;//关联id
        that.stockRecordId = stockRecordId;
        that.ChangeNoType = ChangeNoType;
        this.detailsInfo = [{
          title: '记录信息',
          details: [{
            title: '变更类型',
            value: stockCtypeText
          }, {
            title: ChangeNoType == 1 ? '销售单号' : (ChangeNoType == 2 ? '退回单号' : (ChangeNoType == 3 ? '退货单编号' : '')),
            value: relationStr
          }, {
            title: '变更时间',
            value: createDate
          }, {
            title: '金额变更',
            value: valuationCurrency + amountChangeType + amountChangeNumber2 + ' ( ' + amountBeforeNumber2 + ' --> ' + amountAfterNumber2 + ' )'
          }, {
            title: '负责人',
            value: changeUsername
          }]
        }, {
          title: '供应商信息',
          details: [{
            title: '客户名称',
            value: sdName ? sdName : '无'
          }, {
            title: '联系人',
            value: sdContact ? sdContact : '无'
          }, {
            title: '联系电话',
            value: sdPhone ? sdPhone : '无'
          }, {
            title: '地址',
            value: address ? address : '无'
          }]
        }];
        this.recordInfo = [{
          title: '变更类型',
          value: stockCtypeText
        }, {
          title: ChangeNoType == 1 ? '销售单号' : (ChangeNoType == 2 ? '退回单号' : (ChangeNoType == 3 ? '退货单编号' : '')),
          value: relationStr
        }, {
          title: '变更时间',
          value: createDate
        }, {
          title: '金额变更',
          value: valuationCurrency + amountChangeType + amountChangeNumber2 + ' ( ' + amountBeforeNumber2 + ' --> ' + amountAfterNumber2 + ' )'
        }, {
          title: '负责人',
          value: changeUsername
        }];
        if (sdName && (stockCtype == 'SAOT0A' || stockCtype == 'SAIN0A' || stockCtype == 'FA' ||
          stockCtype == 'FAC' || stockCtype == 'FAG')) {
          this.customerinfo = [{
            title: '客户名称',
            value: sdName ? sdName : '无'
          }, {
            title: '联系人',
            value: sdContact ? sdContact : '无'
          }, {
            title: '联系电话',
            value: sdPhone ? sdPhone : '无'
          }, {
            title: '地址',
            value: address ? address : '无'
          }];
        } else if (sdName && (stockCtype == 'INP0' || stockCtype == 'CINP')) {
          this.supplierInfo = [{
            title: '供应商',
            value: sdName ? sdName : '无'
          }, {
            title: '联系人',
            value: sdContact ? sdContact : '无'
          }, {
            title: '联系电话',
            value: sdPhone ? sdPhone : '无'
          }, {
            title: '地址',
            value: address ? address : '无'
          }];
        }
      })
    },
    // 点击查看退回单、销售单、退货单
    HandleBtn(type, id) {
      let enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
      // 退回单号
      if (type == 'ReturnOrder') {
        if (enterpriseStateInfo.userPermission.i_m == 'D') {
          return this.$common.message('您没有权限查看，请联系企业管理员处理！', 'warning', 2000)
        }
        this.$router.push({
          name: 'InventoryRecordsDetails', query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              id
            }))
          }
        })
      }
      // 销售单
      if (type == 'salesOrder') {
        if (enterpriseStateInfo.userPermission.o_m == 'D') {
          return this.$common.message('您没有权限查看，请联系企业管理员处理！', 'warning', 2000)
        }
        this.$router.push({
          name: 'OrderDetails', query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              id,
              orderStatus: 'All'
            }))
          }
        })
      }
      // 退货单
      if (type == 'returnGoods') {
        if (enterpriseStateInfo.userPermission.o_m == 'D') {
          return this.$common.message('您没有权限查看，请联系企业管理员处理！', 'warning', 2000)
        }
        this.$router.push({
          name: 'ReturnOrderDetails', query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              id,
            }))
          }
        })
      }

    },
    // 查看源记录
    checkSourceRecord() {
      this.productStockAmountListDetails(this.relationId)
    },
    checkstockRecord() {
      this.$router.push({
        name: 'InventoryRecordsDetails',
        query: {
          key: this.$UrlEncode.encode(JSON.stringify({
            id: this.stockRecordId,
            type: 'financial'
          }))
        }
      })
    },
  }
}

</script>
<style lang='less' scoped>
@import "@/assets/css/TableList.less";

.FinancialRecordsDetails {
  .main {
    width: 980px;

    .banner {
      background: url('../../../assets/supply/supplyBanner.png');
      height: 100px;
      color: #FFFFFF;
      font-size: 30px;
      line-height: 100px;
      text-align: center;
      margin-bottom: 40px;
    }

    .infoTable {
      width: 899px;
      padding: 40px;
      border-radius: 10px;

      .title {
        margin-bottom: 22px;
        border-left: 4px solid #0363FA;
        padding-left: 10px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
      }

      .titleItem {
        padding: 0 30px;
        width: 840px;
        height: 50px;
        border: 1px solid #f2f2f2;
        margin-bottom: 5px;

        .titval {
          font-size: 16px;
          color: #999;
          font-weight: 400;
          width: 40%;
        }

        .value {
          line-height: 20px;
          font-size: 16px;
          color: #333;
          font-weight: 400;
          width: 60%;
        }
      }

      .talTit {
        border-top: 1px solid #EDECEC;
        border-left: 1px solid #EDECEC;
        background: #F8F8F8;
        // border-radius: 5px 5px 0px 0;

        span {
          width: calc(100% / 2 - 6px);
          text-align: center;
          line-height: 60px;
          color: #333;
          font-weight: 500;
          font-size: 18px;
          border-right: 1px solid #EDECEC;
        }
      }

      .talTit1 {
        border-top: 1px solid #EDECEC;
        border-left: 1px solid #EDECEC;
        background: #F8F8F8;
        // border-radius: 5px 5px 0px 0;

        span {
          width: calc(100% / 3 - 12px);
          text-align: center;
          line-height: 60px;
          color: #333;
          font-weight: 500;
          font-size: 18px;
          border-right: 1px solid #EDECEC;
        }
      }

      .talVal {
        background: #fff;
        border-left: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;

        span {
          width: calc(100% / 2 - 6px);
          text-align: center;
          line-height: 60px;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          border-right: 1px solid #EDEDED;
        }

        div {
          width: calc(100% / 2 - 6px);
          text-align: center;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          border-right: 1px solid #EDEDED;
          word-break: break-all;
          word-wrap: break-word;
          line-height: 25px;
        }
      }

      .talVal1 {
        background: #fff;
        border-left: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;

        span {
          width: calc(100% / 3 - 12px);
          text-align: center;
          line-height: 60px;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          border-right: 1px solid #EDEDED;
        }

        div {
          width: calc(100% / 3 - 12px);
          text-align: center;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          border-right: 1px solid #EDEDED;
          word-break: break-all;
          word-wrap: break-word;
          line-height: 25px;
        }
      }

      .modifyNote {
        padding: 30px;
        // height: 230px;
        background: #F3F8FE;
        border-radius: 5px;
        color: #666666;
        white-space: pre-line;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }
}</style>
